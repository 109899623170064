import React from "react";
import styled from "styled-components";
import {device} from "../../constants/media";

const Container = styled.main`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

class Content extends React.Component {
  render() {
    return <Container>{this.props.children}</Container>;
  }
}

export default Content;
