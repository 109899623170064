import {withRouter} from "next/router";
import React from "react";
import styled from "styled-components";

import InstagramIcon from "../../assets/img/instagram.svg";
import LinkedInIcon from "../../assets/img/linkedin.svg";
import YouTubeIcon from "../../assets/img/youtube.svg";
import {device} from "../../constants/media";

const languages = ["en", "fa", "de"];

const ToolBarContainer = styled.div`
  @media ${device.below.tablet} {
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem;
  }

  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const LanguageContainer = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
`;

const LanguageItems = styled.li`
  margin: 0 0.5rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.3s;
  white-space: nowrap;

  &:hover {
    color: #000;
  }

  &:focus {
    color: #111;
  }
`;

const SocialItem = styled.a`
  margin: 0 0.4rem;
  text-decoration: inherit;
  border: none;
  color: #888;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SocialIcon = styled.img`
  height: 1.2rem;
`;

class ToolBar extends React.Component {
  changeLanguage(locale) {
    this.props.router.push(this.props.router.route, this.props.router.asPath, {
      locale,
    });
  }

  render() {
    const current_language = this.props.router.locale;

    return (
      <ToolBarContainer>
        <LanguageContainer>
          {languages.map(language => {
            if (current_language === language) {
              return (
                <LanguageItems
                  key={language}
                  style={{
                    fontWeight: "bold",
                    color: "#000",
                    cursor: "not-allowed",
                  }}
                >
                  {language}
                </LanguageItems>
              );
            }

            return (
              <LanguageItems key={language} onClick={() => this.changeLanguage(language)}>
                {language}
              </LanguageItems>
            );
          })}
        </LanguageContainer>

        <SocialItem target="_blank" href="https://www.instagram.com/zavarchitects/">
          <SocialIcon href="/" src={InstagramIcon} />
        </SocialItem>
        <SocialItem
          target="_blank"
          href="https://www.youtube.com/channel/UCcFHzgeojVdODGQO_X_XGsg"
        >
          <SocialIcon href="/" src={YouTubeIcon} />
        </SocialItem>
        <SocialItem
          target="_blank"
          href="https://www.linkedin.com/company/zav-architects/"
        >
          <SocialIcon href="/" src={LinkedInIcon} />
        </SocialItem>
      </ToolBarContainer>
    );
  }
}

export default withRouter(ToolBar);
