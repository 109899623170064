import React from "react";
import styled from "styled-components";
import Logo from "../Logo";
import Menu from "../Menu";
import ToolBar from "../ToolBar";
import {device} from "../../constants/media";
import MobileMenuImage from "../../assets/img/hamburger.png";

const Container = styled.header`
  @media ${device.below.tablet} {
    flex-direction: column;
  }
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: #888;
  position: relative;
  z-index: 10;
  background: #fff;
`;


const MenuWrapper = styled.div`
  @media ${device.below.tablet} {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 2;
    left: 0;
    right: 0;
    background: #fff;
    transition: all 0.5s;
    transform: ${props => (props.open ? "translateY(100px)" : "translateY(-300px)")};
  }

  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.25s linear;
`;

const MobileMenuIconContainer = styled.div`
  width: 35px;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  z-index: 10;

  @media ${device.below.tablet} {
    display: flex;
  }
`;

const MobileMenuIcon = styled.img`
  width: 100%;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.25s linear;
  z-index: 10;

  @media ${device.below.tablet} {
    justify-content: flex-start;
    padding: 0 1rem;
  }
`;

const MainMenu = styled.div`
  @media ${device.below.tablet} {
    width: 100%;
    justify-content: space-between;
  }
  justify-content: space-around;

  flex-grow: 2;
  display: flex;
  align-items: center;
`;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile_menu: false,
    };

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  toggleMobileMenu() {
    this.setState({
      mobile_menu: !this.state.mobile_menu,
    });
  }

  render() {
    return (
      <Container>
        <MainMenu>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <MenuWrapper open={this.state.mobile_menu}>
            <Menu open={this.state.mobile_menu} />
          </MenuWrapper>

          <MobileMenuIconContainer>
            <MobileMenuIcon onClick={this.toggleMobileMenu} src={MobileMenuImage} />
          </MobileMenuIconContainer>
        </MainMenu>

        <ToolBar />
      </Container>
    );
  }
}

export default Header;
