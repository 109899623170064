import fa from "../locales/fa/common.json";
import en from "../locales/en/common.json";
import de from "../locales/de/common.json";

function t(locale, key) {
  switch (locale) {
    case "fa":
      return fa[key];
    case "de":
      return de[key];
    default:
      return en[key];
  }
}


export default t;
