import React from "react";
import styled from "styled-components";
import LogoImage from "../../assets/img/logo.svg";
import Link from "next/link";
import t from "../../utils/locale";
import {withRouter} from "next/router";

const LogoDom = styled.img`
  height: 50px;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 15px;
`;

const StyledLink = styled.a`
  font-size: 0.75rem;
  text-decoration: none;
  color: inherit;
  white-space:nowrap;
`;

class Logo extends React.Component {
  render() {
    const current_language = this.props.router.locale;

    return (
      <React.Fragment>
        <LogoDom href="/" src={LogoImage} />
        <Link href="/" passHref>
          <StyledLink>{t(current_language, "ZAV Architects")}</StyledLink>
        </Link>
      </React.Fragment>
    );
  }
}

export default withRouter(Logo);
