import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import React from "react";
import styled from "styled-components";

import Content from "./Content";
import Header from "./Header";

if (typeof window !== "undefined") {
  LogRocket.init("bournix/borderix");
  setupLogRocketReact(LogRocket);
}

const Container = styled.div`
  background: #fff;
  width: 100%;
  min-width: 100vw;
  overflow-x: hidden;
`;

class Page extends React.Component {
  getHeader() {
    if (this.props.header) {
      return <Header />;
    }
  }

  render() {
    return (
      <Container>
        {this.getHeader()}

        <Content>{this.props.children}</Content>
      </Container>
    );
  }
}

Page.defaultProps = {
  header: true,
};

export default Page;
