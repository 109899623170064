import Link from "next/link";
import {withRouter} from "next/router";
import React from "react";
import styled from "styled-components";

import {device} from "../../constants/media";
import t from "../../utils/locale";

const MenuContainer = styled.ul`
  @media ${device.below.tablet} {
    flex-direction: column;

    li {
      margin-top: 10px;
    }
  }

  flex-direction: row;
  list-style: none;
  display: flex;
  position: relative;
  padding: 0;
`;

const MenuItem = styled.li`
  @media ${device.below.tablet} {
    margin: 0;

    &:hover {
      .sub-menu {
        height: auto;
      }
    }

    &:focus {
      .sub-menu {
        height: auto;
      }
    }
  }

  margin: 0 0.75rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  position: relative;

  a {
    transition: border-bottom 0.3s;
    text-decoration: inherit;
    border: none;
    color: inherit;

    &:hover {
      border-bottom: solid 2px #333;
    }

    &:focus {
      border-bottom: solid 1px #333;
    }
  }

  .sub-menu {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .sub-menu {
      opacity: 1;
      visibility: visible;
    }
  }

  &:focus {
    .sub-menu {
      opacity: 0.8;
      visibility: visible;
    }
  }
`;

const SubMenuContainer = styled.ul`
  @media ${device.below.tablet} {
    flex-direction: column;
    height: 0;
  }

  @media ${device.above.tablet} {
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 0;
    padding-top: 30px;
  }

  transition: all 0.1s;
  list-style: none;
  display: flex;
  margin-left: -0.75rem;
  min-width: max-content;

  &:lang(fa) {
    right: 0;
    left: unset;
    margin-left: 0;
    margin-right: -0.75rem;
    padding-right: 0;
  }
`;

class Menu extends React.Component {
  render() {
    const current_language = this.props.router.locale;

    return (
      <MenuContainer>
        <MenuItem>
          <Link prefetch={false} href="/">
            {t(current_language, "Home")}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link prefetch={false} href="/">
            {t(current_language, "Practice")}
          </Link>
          <SubMenuContainer className="sub-menu">
            <MenuItem>
              <Link prefetch={false} href={"/practice/people"}>
                {t(current_language, "People")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link prefetch={false} href={"/practice/services"}>
                {t(current_language, "Services")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link prefetch={false} href={"/practice/awards"}>
                {t(current_language, "Awards")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link prefetch={false} href={"/practice/office"}>
                {t(current_language, "Office")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link prefetch={false} href={"/practice/publication"}>
                {t(current_language, "Publications")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link prefetch={false} href={"/contact"}>
                {t(current_language, "Contact")}
              </Link>
            </MenuItem>
          </SubMenuContainer>
        </MenuItem>
        <MenuItem>
          <Link prefetch={false} href={"/thoughts"}>
            {t(current_language, "Thoughts")}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link prefetch={false} href={"/works"}>
            {t(current_language, "Works")}
          </Link>
          <SubMenuContainer className="sub-menu">
            <MenuItem>
              <Link prefetch={false} href={"/works/category/all"}>
                {t(current_language, "All")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link prefetch={false} href={"/works/category/residential"}>
                {t(current_language, "Residential")}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link prefetch={false} href={"/works/category/public"}>
                {t(current_language, "Public")}
              </Link>
            </MenuItem>
          </SubMenuContainer>
        </MenuItem>
        <MenuItem>
          <Link prefetch={false} href={"/news"}>
            {t(current_language, "News")}
          </Link>
        </MenuItem>
      </MenuContainer>
    );
  }
}

export default withRouter(Menu);
